import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './EventsDetails.css';
import Navbar from '../components/Navbar';
import inputData from '../components/data.json';
const EventDetails = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [organizationName, setOrganizationName] = useState('');
  const [profile, setprofileName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsData = inputData;
        
        for (let i = 0; i < eventsData.length; i++) {
          const organizationEvents = eventsData[i].Events;
          for (let eventKey in organizationEvents) {
            if (organizationEvents[eventKey]["Event ID"] === eventId) {
              setEvent(organizationEvents[eventKey]);
              setOrganizationName(eventsData[i].OrganizationName);
              setprofileName(eventsData[i].InstagramUsername);
              break;
            }
          }
          if (event) {
            break;
          }
        }

      } catch (error) {
       
      }
    };

    fetchData();

    window.scrollTo(0, 0);
  }, [eventId]);

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <>
      <Navbar /> {/* Navbar added here */}
      <div className="event-details">
        <h2>{organizationName}</h2>  {/* Organization name added here */}
        <a
    href={`https://www.instagram.com/${profile}/`}
    target="_blank"
    rel="noopener noreferrer"
  >
    
  </a>
        <img src={`/.netlify/functions/fetchImage?objectName=${event["Event ID"]}`} alt={eventId} />
        <p> <a
    href={`https://www.instagram.com/${profile}/`}
    target="_blank"
    rel="noopener noreferrer"
  >View Social Media Bio
    
  </a></p>
        <p>Date: {event.ExtractedData}</p>
        <p>Event Details : {event.Comments}</p>
     
      </div>
    </>
  );
};

export default EventDetails;