import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Events from './routes/Events';
import EventDetails from './components/EventDetails';
import Contact from './routes/Contact';
import 'react-datepicker/dist/react-datepicker.css';
import Privacy from './routes/Privacy';
import AddAnEvent from './routes/AddAnEvent';
import Home from './routes/Home';

const userId = 'G-6GEK9KSC4E';

function App() {
  const location = useLocation();

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-6GEK9KSC4E');

    // Replace 'YOUR_USER_ID_HERE' with the actual user ID of the logged-in user
    

    // Report page view to Google Analytics on every route change
    ReactGA.send('pageview', location.pathname + location.search);

    // If the user is on the main page (root path '/'), track the event
    if (location.pathname === "/") {
      ReactGA.event({
        category: 'User',
        action: 'Entered Main Page',
        label: userId, // Track user ID as a label
      });

      
    }
    if (location.pathname === "/addanevent") {
      ReactGA.event({
        category: 'User',
        action: 'Entered Add An Event',
        label: userId, // Track user ID as a label
      });

      
    }
  }, [location])
  

  return (
    <>
      <Routes>
        <Route path="/" element={<Events />} />
        <Route path="/events/:eventId" element={<EventDetails />} />
        <Route path="/addanevent" element={<AddAnEvent/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
