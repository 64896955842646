import React from 'react';
import Navbar from '../components/Navbar';

class AddAnEvent extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.id = "ff-script";
        script.src = "https://formfacade.com/include/104160354086201341380/form/1FAIpQLScSECwCTHdFvEP6tWo9y5gxUhfUeNJodwqzxomKx9NmXNtowA/classic.js?div=ff-compose";
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        return (
            <>
                <Navbar />
                <div>
                    
                    <div id="ff-compose"></div>
                </div>
            </>
        );
    }
}

export default AddAnEvent;