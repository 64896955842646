import React from 'react';
import Navbar from '../components/Navbar';
import './Contactcenter.css';

const Contact = () => {
  return (
    <div className="main-container">
      <Navbar />
      <div className="contact-info-container">
        <h2>Let's Connect!</h2>
        <p>
          Got <span className="highlight-text">questions</span> or <span className="highlight-text">concerns</span>?
        </p>
        <p>
          Eager to showcase your spectacular events with us?
        </p>
        <p>
          We're all ears! Drop us a note at:
        </p>
        <p className="contact-email">
          <strong>contact@mycollegeevents.com</strong>
        </p>
        <p>
          Let's bring your events to life.
        </p>
        <p className="event-removal-text"> {/* New class for the last statement */}
          If you would like to remove your event, contact us.
        </p>
      </div>
    </div>
  );
}

export default Contact;