import React from 'react';
import './Privacy.css';
import Navbar from '../components/Navbar';
const Privacy = () => {
    <Navbar />
  return (
    
      

    <>
    <Navbar />
      
   
    <div className="privacy-policy-container">

     
      <h1>MyCollegeEvents Privacy Policy</h1>
      <p><strong>Effective Date:</strong> Oct 19 2023</p>
      
      <section className="introduction">
        <h2>Introduction</h2>
        <p>Welcome to MyCollegeEvents ("we," "us," or "our"). This Privacy Policy outlines how we collect, use, and protect the information you provide to us or that we may gather when you use our website. Please read this Privacy Policy carefully to understand how we handle your information. By accessing or using our website, you consent to the practices described in this Privacy Policy.</p>
      </section>

      <section className="use-information">
        <h2>How We Use Your Information</h2>
        <p>We use the information collected from Google Analytics to analyze website traffic and improve our website's performance and user experience. We do not collect or store any personal information about our website visitors unless explicitly provided by you through a contact form or other means of communication.</p>
      </section>

      <section className="cookies">
        <h2>Cookies and Similar Technologies</h2>
        <p>We may use cookies and similar technologies to collect information about your use of our website. Cookies are small text files that are stored on your device when you visit a website. We use cookies for various purposes, including to provide a customized experience, analyze user behavior, and improve our website's functionality. You can manage your cookie preferences by adjusting your browser settings.</p>
      </section>

      <section className="third-party-links">
        <h2>Third-Party Links</h2>
        <p>Our website may contain links to third-party websites or services that are not controlled or operated by us. This Privacy Policy does not apply to those third-party websites, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of any third-party websites you visit.</p>
      </section>

      <section className="information-collected">
        <h2>Information We Collect</h2>
        <ul>
          <li>
            <strong>a. Information from third parties:</strong> Our website may display media from third parties. We are not affiliated with these third parties. All rights to third party content belong to their respective owners. This product makes use of third party APIs. All logos and trademarks associated with third parties displayed within this application are owned by those third parties.
          </li>
          <li>
            <strong>b. Google Analytics:</strong> We use Google Analytics to collect and analyze information about how visitors use our website. Google Analytics uses cookies and similar technologies to gather information such as your IP address, browser type, operating system, and pages visited. This information is used to improve the user experience and analyze website traffic. For more information on how Google Analytics collects and processes data, please refer to Google's Privacy Policy.
          </li>
        </ul>
      </section>

      <section className="security">
        <h2>Security</h2>
        <p>We take reasonable steps to protect the information we collect and ensure its security according to industry best practices. We use SSL to secure the privacy of your data across the internet. We use industry controls to prevent unauthorized access to our systems. We routinely monitor access to our administrative systems and utilize industry best practices for account hygiene.</p>
      </section>

      <section className="changes">
        <h2>Changes to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post the updated Privacy Policy on our website with the effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we handle your information.</p>
      </section>

      <section className="contact-us">
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at privacy@mycollegeevents.com.</p>
      </section>

      <footer>
        <p><strong>Last Updated:</strong> 10/24/2023</p>
      </footer>

    </div>

    </>
   
  );
}

export default Privacy;
