
//Navbar.js
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import{FaBars, FaTimes} from 'react-icons/fa'
import './NavbarStyles.css'





const Navbar = () => {
    const[click,setClick] = useState(false)
    const handleClick = () => setClick(!click)




  return (
    <div className="header">
    <Link to='/'><h1>MyCollegeEvents </h1></Link>
    
    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li>
            <Link to='/'> Home </Link>
        </li>

        <li>
            <Link to='/addanevent'> Add An Event </Link>
        </li>
        <li>
            <Link to='/privacy'> Privacy Policy </Link>
        </li>
        <li>
            <Link to='/contact'> Contact </Link>
        </li>
        
        
    </ul>
    <div className="hamburger" onClick={handleClick}>
    {click ? (<FaTimes size={30} style={{color: 'black'}}/>) : ( <FaBars size={30} style={{color: 'black'}}/>)}
</div>

</div>
  )
}

export default Navbar