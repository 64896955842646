import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Eventsjp.css";
import dateicon from '../components/dateicon.gif'
import cursor from '../components/cursor.gif'
import school from '../components/icon-school.gif'
import confetti from '../components/confetti.gif'
import AWS from "aws-sdk";
import localData from '../components/data.json'


const Events = () => {

const startDate2023 = new Date(2024, 0, 8); // January 1, 2023
const endDate2023 = new Date(2024, 1, 31); // December 31, 2023
const [selectedCollege, setSelectedCollege] = useState('UCLA'); // State for selected college
const [isCollegePickerOpen, setIsCollegePickerOpen] = useState(false);
  const collegePickerRef = useRef();


const isEqualDate = (d1, d2) => {
  return (
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()

     
  );
};

const toggleTagPicker = () => {
  setIsTagPickerOpen(prev => !prev); // Toggle the visibility of the tag picker
  setIsDatePickerOpen(false); // Ensure the date picker is closed
};

const toggleDatePicker = () => {
  setIsDatePickerOpen(prev => !prev); // Toggle the visibility of the date picker
  setIsTagPickerOpen(false); // Ensure the tag picker is closed
};

const toggleCollegePicker = (event) => {
  event.stopPropagation(); // Prevent event from bubbling up to document
  setIsCollegePickerOpen(prev => !prev);
};

const handleCollegeChange = (event) => {
  setSelectedCollege(event.target.value);
};



const today = new Date();
today.setHours(0, 0, 0, 0);
 
  const defaultDate = new Date(2023, 9, 20);  // JavaScript months are 0-based, so October is 9
  const [selectedDates, setSelectedDates] = useState([new Date()]);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTagPickerOpen, setIsTagPickerOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(() => {
    const storedTags = localStorage.getItem("selectedTags");
    return storedTags ? JSON.parse(storedTags) : [];
  });
  const [eventsData, setEventsData] = useState([]);
  const datePickerRef = useRef();
  const tagPickerRef = useRef();

  const availableTags = [
    "Arts & Crafts",
    "Business",
    "Career",
    "Cultural",
    "Engineering",
    "Food",
    "Greek Life",
    "Health & Pre-Med",
    "Outdoor",
    "Party",
    "Performing Arts",
    "PoliSci",
    "Religious",
    "Seminars",
    "Sports",
    "Volunteering",
    "Workshops",
  ];
  useEffect(() => {
    setEventsData(localData);
  }, []);

  const parseDate = (date) => {
    if (typeof date !== "string" || date.trim() === "") {
      return [];
    }

    const dateWithoutBrackets = date.replace(/\[|\]/g, "");
    const dates = dateWithoutBrackets.split(",");
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    return dates.map((date) => {
      const [month, day] = date
        .trim()
        .split("/")
        .map((str) => parseInt(str, 10));
      return new Date(year, month - 1, day);
    });
  };

  const isEventFutureOrToday = (eventDates) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds
    return eventDates.some((eventDate) => eventDate >= today);
  };

  const clearDates = () => {
    setSelectedDates([]);
    localStorage.removeItem("selectedDates");
  };

  const parseTags = (tags) => {
    if (typeof tags !== "string" || tags.trim() === "") {
      return [];
    }

    // Remove the brackets
    const tagsWithoutBrackets = tags.replace(/\[|\]/g, "");


    // Split the string into an array and trim each tag
    const parsedTags = tagsWithoutBrackets.split(",").map((tag) => tag.trim());

    return parsedTags;
  };

  const computeTagRelevance = (eventTags, userTags) => {
    const firstMatchingTag = userTags.find((userTag) =>
      eventTags.includes(userTag)
    );

    if (!firstMatchingTag) {
      return 0; // No matching tags, relevance is 0
    }

    const firstMatchingTagIndex = eventTags.indexOf(firstMatchingTag);
    let relevance = 0;

    switch (firstMatchingTagIndex) {
      case 0:
        relevance += 3;
        break;
      case 1:
        relevance += 2;
        break;
      default:
        relevance += 1;
        break;
    }
    if (eventTags.includes("Food")) {
      relevance += 3;
    }

    return relevance;
  };
  const handleDateChange = (date) => {
    // Clone the selected dates array
    let newSelectedDates = [...selectedDates];
  
    // Check if the clicked date is already selected
    const isAlreadySelected = newSelectedDates.some(selectedDate => isEqualDate(selectedDate, date));
  
    if (isAlreadySelected) {
      // If the clicked date is already selected, unselect it
      newSelectedDates = newSelectedDates.filter(selectedDate => !isEqualDate(selectedDate, date));
    } else {
      // If the clicked date is not selected, add it to the selected dates
      newSelectedDates.push(date);
    }
  
    // Set the selected dates
    setSelectedDates(newSelectedDates);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsDatePickerOpen(false);
    }
    if (tagPickerRef.current && !tagPickerRef.current.contains(event.target)) {
      setIsTagPickerOpen(false);
    }
  };





  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("selectedTags", JSON.stringify(selectedTags));
  }, [selectedTags]);



  const loadMoreRef = useRef(null);

  

  return (
    <div className="events-container">
      <Navbar />
      <h1 className="bigBoldBlack">

</h1>
      <div class="feature-container">
        
      <div class="feature-box" onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
        <div class="icon"><img src={dateicon} alt="Date Icon" class="transparent-background" /></div>
        <h3>Search By Date(s)</h3>
        <p>Click on here to open a calendar to find events by day(s) </p>
        {/* Display selected dates */}
        {selectedDates.length > 0 && (
            <p style={{ fontWeight: 'bold' }}>
                Selected Dates: {selectedDates.map(date => date.toLocaleDateString(undefined, { month: "numeric", day: "numeric" })).join(", ")}
            </p>
        )}
         {/* Calendar container */}
    {isDatePickerOpen && (
        <div 
            className="date-picker-container" 
            ref={datePickerRef} 
            onClick={(e) => e.stopPropagation()} // Prevent click inside calendar from closing it
        >
            <Calendar
  minDate={startDate2023}
  maxDate={endDate2023}
  tileClassName={({ date }) => {
    const isSelected = selectedDates.some(selectedDate => isEqualDate(selectedDate, date));
    return isSelected ? "highlighted-date" : "";
  }}
  onClickDay={handleDateChange}
  value={selectedDates}
/>
        </div>
    )}

       {/* Clear Dates Button */}
{selectedDates.length > 0 && isDatePickerOpen && (
    <button onClick={(e) => {
        e.stopPropagation(); // Prevent feature-box onClick from triggering
        clearDates();
    }} className="clear-dates-button">
        Clear Dates
    </button>
)}


           {/* Conditional Rendering of Calendar */}
   
    </div>

   

    <div className="feature-box" onClick={() => {
    setIsTagPickerOpen(prevState => !prevState); // Toggle the tag picker
    setIsDatePickerOpen(false); // Close the date picker if it's open
}}>
        <div class="icon"><img src={cursor} alt="Cursor Icon" class="transparent-background" /></div>
        <h3 style={{ fontWeight: 'bold' }}>Select Tags</h3>
        <p>Click here to filter by tags</p>
        {/* Display selected tags */}
        {selectedTags.length > 0 && (
           <p style={{ fontWeight: 'bold' }}>
           Selected Tags: {selectedTags.join(", ")}
         </p>
        )}
        {/* Clear Tags Button */}
        {selectedTags.length > 0 && (
            <button onClick={(e) => {
                e.stopPropagation(); // Prevent feature-box onClick from triggering
                setSelectedTags([]);
            }} className="college-dropdown">
                Clear Tags
            </button>
        )}
    </div>

    {/* Tag Picker */}
    {isTagPickerOpen && (
        <div className="tags-list" ref={tagPickerRef}>
            {availableTags.map((tag) => (
                <div key={tag}>
                    <input
                        type="checkbox"
                        id={tag}
                        name={tag}
                        checked={selectedTags.includes(tag)}
                        onChange={() => {
                            setSelectedTags((prevTags) =>
                                prevTags.includes(tag)
                                    ? prevTags.filter((t) => t !== tag)
                                    : [...prevTags, tag]
                            );
                        }}
                    />
                    <label htmlFor={tag}>{tag}</label>
                </div>
            ))}
        </div>
    )}

  

<div className="feature-box">
      <div className="icon"><img src={school} alt="School Icon" class="transparent-background" />
        {/* Add an icon for the college picker */}
      </div>
      <h3>Pick a College</h3>
      <p>Click here to filter by college</p>
      
      
      {/* College Picker Dropdown always visible inside the Feature Box */}
      <select onChange={handleCollegeChange} value={selectedCollege} className="college-dropdown">
        <option value="UCLA">UCLA</option>
        <option value="UCSD">UCSD</option>
        {/* Add other colleges here */}
      </select>
    </div>


       
    
  
</div>

      
      <div className="events-grid">
  {eventsData.map((event) => {
    const isEventFromSelectedCollege = selectedCollege === '' || event.College === selectedCollege;
    if (!event.Events || Object.keys(event.Events).length === 0 || !isEventFromSelectedCollege) {
      return null;
    }
    if (!event.Events || Object.keys(event.Events).length === 0) {
      return null;
    }

    const eventKeys = Object.keys(event.Events).sort((a, b) => {
      const aRelevance = computeTagRelevance(
        parseTags(event.Events[a].Tag),
        selectedTags
      );
      const bRelevance = computeTagRelevance(
        parseTags(event.Events[b].Tag),
        selectedTags
      );
      return bRelevance - aRelevance;
    });

    return eventKeys.map((eventId) => {
      const eventData = event.Events[eventId];
      const eventDates = parseDate(eventData.ExtractedData);
      const eventTags = parseTags(eventData.Tag);
      const eventRelevance = computeTagRelevance(eventTags, selectedTags);
      
      let imageUrl;
        // If not in cache, fetch the image URL and update the cache
        imageUrl = `/.netlify/functions/fetchImage?objectName=${eventData["Event ID"]}`;
     

      const isEventOnSelectedDate = eventDates.some((eventDate) =>
        selectedDates.some(
          (selectedDate) =>
            eventDate.getDate() === selectedDate.getDate() &&
            eventDate.getMonth() === selectedDate.getMonth()
        )
      );

      const matchingSelectedDate = eventDates.find((eventDate) =>
  selectedDates.some(
    (selectedDate) =>
      eventDate.getDate() === selectedDate.getDate() &&
      eventDate.getMonth() === selectedDate.getMonth()
  )
);


      const formattedStartDate = matchingSelectedDate
      ? `2023${(matchingSelectedDate.getMonth() + 1).toString().padStart(2, '0')}${matchingSelectedDate.getDate().toString().padStart(2, '0')}`
      : '';

      const calendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.OrganizationName)}&dates=${formattedStartDate}/${formattedStartDate}&details=${encodeURIComponent(eventData.Comments)}&location=${encodeURIComponent("https://www.mycollegeevents.com"+ imageUrl)}&sf=true&output=xml`;
      
      // Assuming event has a property called 'ExtractedData' which contains the dates
     
   

      const isEventRelevantByTag =
        eventRelevance > 1 && isEventFutureOrToday(eventDates);
      const isEventRelevantByDate =
        selectedDates.length === 0 || isEventOnSelectedDate;

      let shouldDisplayEvent = false;



    
        if (selectedTags.length === 0 && selectedDates.length === 0) {
          // Case when no dates or tags are selected - show nothing
          shouldDisplayEvent = false;
      } else if (selectedTags.length === 0 && selectedDates.length > 0) {
          shouldDisplayEvent = isEventRelevantByDate && isEventFromSelectedCollege;
      } else if (selectedTags.length > 0 && selectedDates.length === 0) {
        shouldDisplayEvent = isEventRelevantByTag && isEventFromSelectedCollege;
      } else if (selectedTags.length > 0 && selectedDates.length > 0) {
        shouldDisplayEvent = isEventRelevantByTag && isEventRelevantByDate && isEventFromSelectedCollege;
      } else {
        // Case when no filters are applied
        shouldDisplayEvent = isEventFromSelectedCollege;
      }

      
  if (shouldDisplayEvent) {
    
        return (
          
          <div className="event-item" >
       <h2>
  <a
    href={`https://www.instagram.com/${event.InstagramUsername}/`}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: 'black' }}
  >
    {event.OrganizationName}
  </a>
</h2>

            
            <img src={imageUrl} alt={event["Event ID"]} loading="lazy" />
            <p style={{ fontSize: 'larger',fontWeight: 'bold' }}>Date: {eventData.ExtractedData}</p>
            {/* Instagram Profile Link */}
              
            <Link
              to={`/events/${eventData["Event ID"]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Details
            </Link>

        {/* Google Calendar Link */}
        {matchingSelectedDate && (
                <a
                  href={calendarLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add to Google Calendar
                </a>
              )}




                  
            <div className="event-tags">
              {eventTags.map((tag) => (
                <span key={tag} className="event-tag">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  })}
</div>
    </div>
  );
};

export default Events;
